/*
  This css is only required due to the response coming from "usePublicMarketingContent"
  contains html, hence we need to have to have the css classes they have assiged to these elements...
*/

a.telia-button,
button.telia-button {
  position: relative;
  border-radius: 999px;
  text-decoration: unset;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: TeliaSans, Helvetica, Arial;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1.3;
  outline: 0;
  padding: 14px 24px;
  top: 8px;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}

a.telia-button--primary:hover,
a.telia-button--primary:active,
button.telia-button--primary:hover,
button.telia-button--primary:active {
  background-color: #5f008f !important;
}

a.button--primary:hover,
a.button--primary:active,
button.button--primary:hover,
button.button--primary:active {
  background-color: #5f008f !important;
}

a.telia-button--secondary:hover,
a.telia-button--secondary:active,
button.telia-button--secondary:hover,
button.telia-button--secondary:active {
  background-color: #990ae3;
  color: white;
}

a.telia-button--primary,
a.telia-button--primary:focus,
button.telia-button--primary,
button.telia-button--primary:focus {
  color: white;
  background-color: #990ae3;
}

.telia-button--secondary {
  background-color: transparent;
  border: 0.2rem solid #990ae3;
  color: #990ae3;
  padding: 14px 24px;
}

.button {
  background-color: #ffffff;
  border: 1px solid #990ae3;
  border-radius: 2rem;
  color: #990ae3;
  cursor: pointer;
  display: inline-block;
  font-family: "TeliaSans-Medium", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.05rem;
  line-height: 1;
  margin: 0 1rem 0 0;
  padding: 1rem 1.8rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.15s, color 0.15s;
}
